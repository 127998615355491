import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "report row",
  id: "top"
}
const _hoisted_2 = { class: "report__links-container col-12 col-xl-3" }
const _hoisted_3 = {
  key: 0,
  class: "report__error col-12 col-xl-9"
}
const _hoisted_4 = {
  key: 1,
  class: "report__container col-12 col-xl-9"
}
const _hoisted_5 = {
  class: "report__details-container",
  id: "details"
}
const _hoisted_6 = {
  class: "report__history-container",
  id: "history"
}
const _hoisted_7 = {
  class: "report__responses-container",
  id: "responses"
}
const _hoisted_8 = {
  class: "report__cover-container",
  id: "cover"
}
const _hoisted_9 = {
  class: "report__notifications-container",
  id: "notifications"
}
const _hoisted_10 = {
  class: "report__messages-container",
  id: "messages"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportLinks = _resolveComponent("ReportLinks")!
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_ReportDetails = _resolveComponent("ReportDetails")!
  const _component_ReportHistory = _resolveComponent("ReportHistory")!
  const _component_ReportResponses = _resolveComponent("ReportResponses")!
  const _component_ReportCoverMoves = _resolveComponent("ReportCoverMoves")!
  const _component_ReportNotifications = _resolveComponent("ReportNotifications")!
  const _component_ReportMessages = _resolveComponent("ReportMessages")!
  const _component_ReportFooter = _resolveComponent("ReportFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ReportLinks)
    ]),
    _createVNode(_component_LoadingSpinner, {
      class: "appliances-table__appliance-spinner",
      "loading-state": "reportLoading"
    }),
    (_ctx.reportStore.errorWithData)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_3, " Couldn't retrive data from server, please try again soon! "))
      : _createCommentVNode("", true),
    (_ctx.reportStore.report.cadEventCommentResults)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_ReportDetails)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_ReportHistory)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_ReportResponses)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_ReportCoverMoves)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_ReportNotifications)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_ReportMessages)
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_ReportFooter)
  ]))
}