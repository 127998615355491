
import useBreakpoints from "@/helpers/breakpoint";
import { useReportStore } from "@/store/report";
import { defineComponent, Ref, ref } from "vue";
import NotificationTag, { NOTIFICATIONTYPES } from "../NotificationTag.vue";

export default defineComponent({
  name: "ReportLinks",
  components: { NotificationTag },
  setup() {
    const breakpoint = useBreakpoints();
    const reportStore = useReportStore();
    const isOpen = ref(breakpoint.type.value == "desktop");
    const activeLink: Ref<SECTION> = ref(SECTION.DETAILS);
    const toggleMenu = () => {
      isOpen.value = !isOpen.value;
    };
    const handleClick = (clickedSection: SECTION) => {
      if (breakpoint.type.value !== "desktop") isOpen.value = false;
      if (
        activeLink.value === SECTION.MESSAGES &&
        reportStore.getUnreadMessageCount > 0
      )
        reportStore.markMessagesAsRead();
      if (
        activeLink.value === SECTION.NOTIFICATIONS &&
        reportStore.getUnreadNotificationCount > 0
      )
        reportStore.markNotificationsAsRead();

      activeLink.value = clickedSection;
    };
    return {
      isOpen,
      activeLink,
      toggleMenu,
      handleClick,
      reportStore,
      SECTION,
      NOTIFICATIONTYPES,
    };
  },
});

const SECTION = {
  DETAILS: "details",
  HISTORY: "history",
  RESPONSES: "responses",
  COVER: "cover",
  NOTIFICATIONS: "notifications",
  MESSAGES: "messages",
} as const;
// eslint-disable-next-line no-redeclare
type SECTION = typeof SECTION[keyof typeof SECTION];
