export function fixCapitalization(dataString: string): string {
  if (!dataString) return "";
  const words = dataString?.split(" ");
  const fixedWords = words.map((word) =>
    word.length < 2 ? word : word.charAt(0) + word.slice(1).toLocaleLowerCase()
  );
  return fixedWords.join(" ");
}

export function fixCapitalizationAndSpaces(dataString?: string): string {
  if (!dataString) return "";
  dataString = dataString.toLocaleLowerCase();
  const regex = /(\b[a-z](?!\b))/g;

  const newString = dataString.replace(regex, function (x) {
    return x.toUpperCase();
  });

  return newString;
}

// TODO check if FENZ will be formatting their data like this in future
export function breakOnCommasAndAddSpaces(dataString?: string): string {
  if (!dataString) return "";

  const splitWords = dataString.split(/(?<=[,])/);
  return splitWords.join(" ");
}
