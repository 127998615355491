import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "report-section-header__heading-left d-flex flex-column flex-md-row justify-content-md-between" }
const _hoisted_2 = { class: "report-section-header__heading-left__top d-flex align-items-center" }
const _hoisted_3 = { class: "report-section-header__heading" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["report-section-header d-flex flex-row justify-content-between align-items-center", { open: _ctx.isOpen }]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('filterToggled')))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _renderSlot(_ctx.$slots, "notificationTag")
      ]),
      _renderSlot(_ctx.$slots, "filterMessage")
    ]),
    _createVNode(_component_font_awesome_icon, {
      icon: _ctx.isOpen ? 'minus' : 'plus',
      class: "report-messages__heading__icon"
    }, null, 8, ["icon"])
  ], 2))
}