
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReportSectionHeader",
  components: {},
  emits: ["filterToggled"],
  props: {
    isOpen: Boolean,
  },
});
