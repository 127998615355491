
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import ReportLinks from "../components/report/ReportLinks.vue";
import ReportDetails from "../components/report/ReportDetails.vue";
import ReportHistory from "../components/report/ReportHistory.vue";
import ReportResponses from "../components/report/ReportResponses.vue";
import ReportCoverMoves from "../components/report/ReportCoverMoves.vue";
import ReportNotifications from "../components/report/ReportNotifications.vue";
import ReportMessages from "../components/report/ReportMessages.vue";
import ReportFooter from "../components/report/ReportFooter.vue";
import LoadingSpinner from "../components/LoadingSpinner.vue";
import { useReportStore } from "@/store/report";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Report",
  components: {
    ReportLinks,
    ReportDetails,
    ReportHistory,
    ReportResponses,
    ReportCoverMoves,
    ReportNotifications,
    ReportMessages,
    ReportFooter,
    LoadingSpinner,
  },

  setup() {
    const reportStore = useReportStore();
    const route = useRoute();
    const cadNumber = route.params.id as string;
    reportStore.loadReportFromApi(cadNumber);
    const pollId = ref(0);
    document.title = cadNumber;

    onMounted(() => {
      pollId.value = setInterval(
        reportStore.updateReportData,
        reportStore.pollTimeSeconds * 1000
      );
    });
    onUnmounted(() => {
      clearInterval(pollId.value);
    });
    return { reportStore };
  },
});
