
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

export default defineComponent({
  name: "ReportSectionObserver",
  props: {
    isOpen: Boolean,
    markReadFunction: { type: Function, required: true },
  },
  setup(props) {
    const containerRef = ref<HTMLDivElement | null>(null);
    const observer = ref<IntersectionObserver | null>(null);
    const prevTableOnScreen = ref(false);
    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (prevTableOnScreen.value && !entry.isIntersecting) {
          props.markReadFunction();
          prevTableOnScreen.value = false;
        }
        if (entry.isIntersecting) prevTableOnScreen.value = true;
      });
    };
    onMounted(() => {
      observer.value = new IntersectionObserver(observerCallback, {
        rootMargin: "0px 0px -100px 0px",
      });
      observer.value.observe(containerRef.value as Element);
    });
    onUnmounted(() => {
      observer.value?.disconnect();
    });
    return {
      containerRef,
    };
  },
});
