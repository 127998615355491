
import { defineComponent } from "vue";

export default defineComponent({
  name: "ReportFooter",
  components: {},
  setup() {
    const handleDownload = () => {
      window.print();
    };
    return { handleDownload };
  },
});
