
import { formatDateThenTime } from "@/helpers/dateHelpers";
import { generateMapLink } from "@/helpers/actionLinks";
import { fixCapitalization } from "@/helpers/stringHelpers";
import { useReportStore } from "@/store/report";
import { defineComponent, computed } from "vue";
import IncidentIcon from "../IncidentIcon.vue";

export default defineComponent({
  name: "ReportDetails",
  components: { IncidentIcon },
  setup() {
    const reportStore = useReportStore();

    const cadSummary = computed(() => reportStore.report.cadEventSummaryResult);
    return {
      cadSummary,
      generateMapLink,
      formatDateThenTime,
      fixCapitalization,
    };
  },
});
