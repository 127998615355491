
import ReportSectionHeader from "./ReportSectionHeader.vue";
import GenericTableHeaderButton from "../GenericTableHeaderButton.vue";
import { defineComponent, ref } from "vue";
import { useReportStore } from "@/store/report";
import { formatDayMonthTime } from "@/helpers/dateHelpers";

export default defineComponent({
  name: "ReportResponses",
  components: { ReportSectionHeader, GenericTableHeaderButton },
  setup() {
    const isOpen = ref(true);
    const toggleAccordion = () => {
      isOpen.value = !isOpen.value;
    };
    const reportStore = useReportStore();
    return {
      isOpen,
      toggleAccordion,
      reportStore,
      formatDayMonthTime,
    };
  },
});
