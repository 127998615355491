import { computed, ComputedRef, onMounted, onUnmounted, ref } from "vue";

export default function useBreakpoints(): {
  width: ComputedRef<number>;
  type: ComputedRef<string>;
} {
  const windowWidth = ref(window.innerWidth);

  const onWidthChange = () => (windowWidth.value = window.innerWidth);
  onMounted(() => window.addEventListener("resize", onWidthChange));
  onUnmounted(() => window.removeEventListener("resize", onWidthChange));

  const type = computed((): string => {
    if (windowWidth.value < 768) return "mobile";
    if (windowWidth.value >= 768 && windowWidth.value < 1080) return "tablet";
    return "desktop";
  });

  const width = computed(() => windowWidth.value);

  return { width, type };
}
