
import { formatTimeThenDate, elapsedTime } from "@/helpers/dateHelpers";
import ReportSectionHeader from "./ReportSectionHeader.vue";
import GenericTableHeaderButton from "../GenericTableHeaderButton.vue";
import { computed, defineComponent, ref } from "vue";
import { useReportStore } from "@/store/report";

export default defineComponent({
  name: "ReportHistory",
  components: { ReportSectionHeader, GenericTableHeaderButton },
  setup() {
    const isOpen = ref(true);
    const toggleAccordion = () => {
      isOpen.value = !isOpen.value;
    };

    const reportStore = useReportStore();

    const generateHistoryItem = (label: string, date?: Date) => {
      return {
        label: label,
        time: computed(() => {
          const time = formatTimeThenDate(date);
          return time.length > 0 ? time : "-";
        }),
        elapsedTime: computed(() => {
          const time = elapsedTime(
            reportStore.report.cadEventHistoryResult.createdTimestamp,
            date
          );
          return time.length > 0 ? time : "-";
        }),
      };
    };

    const historyItems = [
      generateHistoryItem(
        "Created:",
        reportStore.report.cadEventHistoryResult.createdTimestamp
      ),
      generateHistoryItem(
        "Confirmed:",
        reportStore.report.cadEventHistoryResult.confirmedTimestamp
      ),
      generateHistoryItem(
        "Alerted:",
        reportStore.report.cadEventHistoryResult.alertedTimestamp
      ),
      generateHistoryItem(
        "1st Response:",
        reportStore.report.cadEventHistoryResult.firstResponseTimestamp
      ),
      generateHistoryItem(
        "1st Arrival:",
        reportStore.report.cadEventHistoryResult.firstArrivalTimestamp
      ),
      generateHistoryItem(
        "2nd Arrival:",
        reportStore.report.cadEventHistoryResult.secondArrivalTimestamp
      ),
      generateHistoryItem(
        "3rd Arrival:",
        reportStore.report.cadEventHistoryResult.thirdArrivalTimestamp
      ),
      generateHistoryItem(
        "Stop:",
        reportStore.report.cadEventHistoryResult.stopTimestamp
      ),
      generateHistoryItem(
        "Closed:",
        reportStore.report.cadEventHistoryResult.closedTimestamp
      ),
    ];

    return {
      isOpen,
      toggleAccordion,
      reportStore,
      historyItems,
      formatTimeThenDate,
      elapsedTime,
    };
  },
  methods: {},
});
